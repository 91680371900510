<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items">
        <div class="one-item">
          <label>用户名</label>
          <a-input class="input" v-model="queryParams.username" allowClear/>
        </div>

        <div class="one-item">
          <label>手机号</label>
          <a-input class="input" v-model="queryParams.tel" allowClear/>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <div>
      <div class="operator">
        <a-button type="primary" ghost @click="add" v-hasPermission="'user:add'">新增</a-button>
        <a-button @click="batchDelete" v-hasPermission="'user:delete'">删除</a-button>
        <!--        <a-dropdown v-hasAnyPermission="'user:reset','user:export'">-->
        <!--          <a-menu slot="overlay">-->
        <!--            <a-menu-item v-hasPermission="'user:reset'" key="password-reset" @click="resetPassword">密码重置</a-menu-item>-->
        <!--            <a-menu-item v-hasPermission="'user:export'" key="export-data" @click="exportExcel">导出Excel</a-menu-item>-->
        <!--          </a-menu>-->
        <!--          <a-button>-->
        <!--            更多操作-->
        <!--            <a-icon type="down"/>-->
        <!--          </a-button>-->
        <!--        </a-dropdown>-->
      </div>
      <!-- 表格区域 -->
      <a-table ref="TableInfo" size="small" :bordered="true"
               :columns="columns"
               rowKey="userId"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
               :scroll="{ x: 900 }"
               @change="handleTableChange">
        <template slot="email" slot-scope="text, record">
          <a-popover placement="topLeft">
            <template slot="content">
              <div>{{ text }}</div>
            </template>
            <p style="width: 150px;margin-bottom: 0">{{ text }}</p>
          </a-popover>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon v-hasPermission="'user:update'" type="setting" theme="twoTone" twoToneColor="#4a9ff5"
                  @click="edit(record)" title="修改用户"></a-icon>
          &nbsp;
          <a-icon v-hasPermission="'user:view'" type="eye" theme="twoTone" twoToneColor="#42b983" @click="view(record)"
                  title="查看"></a-icon>
          <a-badge v-hasNoPermission="'user:update','user:view'" status="warning" text="无权限"></a-badge>
        </template>
      </a-table>
    </div>
    <!-- 用户信息查看 -->
    <user-info
        :userInfoData="userInfo.data"
        :userInfoVisiable="userInfoVisible"
        @close="handleUserInfoClose">
    </user-info>
    <!-- 新增用户 -->
    <user-add
        @close="handleUserAddClose"
        @success="handleUserAddSuccess"
        :visible="userAddVisible">
    </user-add>
    <!-- 修改用户 -->
    <user-edit
        ref="userEdit"
        @close="handleUserEditClose"
        @success="handleUserEditSuccess"
        :userEditVisiable="userEditVisible">
    </user-edit>
  </a-card>
</template>

<script>
import UserInfo from './UserInfo'
import UserAdd from './UserAdd'
import UserEdit from './UserEdit'
import {ListMixin} from '@/mixin/list-mixin'

export default {
  name: 'User',
  components: {UserInfo, UserAdd, UserEdit},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: 'user',
      userInfoVisible: false,
      userInfo: {
        data: {}
      },
      searchSaler: {},
      userAddVisible: false,
      userEditVisible: false,
    }
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}
      return [{
        title: '用户名',
        dataIndex: 'username',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'username' && sortedInfo.order
      },
        //   {
        //   title: '性别',
        //   dataIndex: 'ssex',
        //   customRender: (text, row, index) => {
        //     switch (text) {
        //       case '0':
        //         return '男'
        //       case '1':
        //         return '女'
        //       case '2':
        //         return '保密'
        //       default:
        //         return text
        //     }
        //   },
        //   filters: [
        //     { text: '男', value: '0' },
        //     { text: '女', value: '1' },
        //     { text: '保密', value: '2' }
        //   ],
        //   filterMultiple: false,
        //   filteredValue: filteredInfo.ssex || null,
        //   onFilter: (value, record) => record.ssex.includes(value)
        // }, {
        //   title: '邮箱',
        //   dataIndex: 'email',
        //   scopedSlots: { customRender: 'email' },
        //   width: 100
        // },
        {
          title: '部门',
          dataIndex: 'deptName'
        }, {
          title: '电话',
          dataIndex: 'mobile'
        }, {
          title: '状态',
          dataIndex: 'status',
          customRender: (text, row, index) => {
            switch (text) {
              case 0:
                return <a-tag color="red">锁定</a-tag>
              case 1:
                return <a-tag color="cyan">有效</a-tag>
              default:
                return text
            }
          }
        }, {
          title: '角色',
          dataIndex: 'roleName',
        }, {
          title: '合作商',
          dataIndex: 'systemName',
        }, {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'createTime' && sortedInfo.order
        }, {
          title: '操作',
          align: 'center',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }]
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    handleSalerChange(value) {
      this.searchSaler = value
    },
    view(record) {
      this.userInfo.data = record
      this.userInfoVisible = true
    },
    add() {
      this.userAddVisible = true
    },
    handleUserAddClose() {
      this.userAddVisible = false
    },
    handleUserAddSuccess() {
      this.userAddVisible = false
      this.$message.success('新增用户成功，初始密码为1234qwer')
      this.search()
    },
    edit(record) {
      this.$refs.userEdit.setFormValues(record)
      this.userEditVisible = true
    },
    handleUserEditClose() {
      this.userEditVisible = false
    },
    handleUserEditSuccess() {
      this.userEditVisible = false
      this.$message.success('修改用户成功')
      this.search()
    },
    handleUserInfoClose() {
      this.userInfoVisible = false
    },
    handleDeptChange(value) {
      this.queryParams.deptId = value || ''
    },
    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    batchDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择需要删除的记录')
        return
      }
      let that = this
      this.$confirm({
        title: '确定删除所选中的记录?',
        content: '当您点击确定按钮后，这些记录将会被彻底删除',
        centered: true,
        onOk() {
          let userIds = that.selectedRowKeys
          that.$delete('user/' + userIds.join(',')).then(() => {
            that.$message.success('删除成功')
            that.selectedRowKeys = []
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
    resetPassword() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择需要重置密码的用户')
        return
      }
      let that = this
      this.$confirm({
        title: '确定重置选中用户密码?',
        content: '当您点击确定按钮后，这些用户的密码将会重置为1234qwer',
        centered: true,
        onOk() {
          let usernames = []
          for (let key of that.selectedRowKeys) {
            usernames.push(that.dataSource[key].username)
          }
          that.$put('user/password/reset', {
            usernames: usernames.join(',')
          }).then(() => {
            that.$message.success('重置用户密码成功')
            that.selectedRowKeys = []
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
    exportExcel() {
      let {sortedInfo, filteredInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.$export('user/excel', {
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo
      }, '用户信息')
    },
    //由list-mixin中统一处理查询动作，如果需要特殊处理查询参数，在这里增加即可
    beforeFetch(params = {}) {
      this.loading = true
      // params.systemId = 1
    }
  }
}
</script>
<style lang="less" scoped>
//@import "../../../../static/less/Common";
</style>
